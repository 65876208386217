<template>
    <div class="container">
        
		<div>
			<h3>Qualification</h3>
		</div>
		<section class="scrollable wrapper" style="text-align: center;">
			<div class="fakeloader" style="position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; background-color: rgb(255, 255, 255); display: none; z-index: 999899999;">
				<div class="fl" style="position: absolute; left: 683px; top: 218px;">
					<img src="img/ajax-loader.gif">
				</div>
			</div>
			<br>
			<div class="dispatch">
				<img src="img/lead-dispatch.png" style="width: 25%;" />
				<p style="width: 56%; margin: 15px auto;">{{ $t('HandleInbound')}}</p>
				<button class="btn-clickstart-md" href="#" @click="actionClick">{{ $t('ClickToStart')}}</button>
			</div>
		</section>
		
		<b-modal id="bv-modal-qualification" centered scrollable hide-footer hide-header size="xl" no-close-on-backdrop>
			<div class="d-block">
				<div class="row">
					<div class="col-md-12 p-0">
						
						<center id="bip">
							<div class="alert alert-warning" style="margin: 0; font-size: 1.2rem;">
								{{ $t('YouHaveToQualify') }}
								<a @click="cancelQualification()" title="Close" class="close-qualification">&times;</a>
							</div>
						</center>
						
					</div>
				</div>
				<div class="row">
					<div class="col-md-7" style="overflow-y: auto; max-height: 38em;height: 38em;">
						<div class="row element-details p-3">
							<div class="col-md-2">
								<div style="width: 75px;height: 75px;background-color: #377dff1f;display: flex;flex-direction: column;justify-content: center;align-items: center;border-radius: 100px;color: #377dff;padding: 0 1rem;margin-top: .6rem;border: 0px solid;">
									<span style="font-size: 12px;">{{ $t('Score') }}</span>
									<span style="font-weight: 600;font-size: 1.4rem;">{{ selectedLead.entry.score }}</span>
								</div>
							</div>
							
							<div class="col-md-7">
								<h5 style="color: #377dff; display:flex; align-items: center; font-size: 18px;">
									<div :title="selectedLead.entry.firstname + ' ' + selectedLead.entry.lastname"> {{ selectedLead.entry.firstname | capitalize }} {{ selectedLead.entry.lastname | capitalize }} </div>
									<b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="dropdown-phone-md"> 
										<template v-slot:button-content>
											<a href="#" id="callPopupBtn" title="Popover title" style="background: #999999;padding: 3.5px;border-radius: 50%;width: 22px;height: 22px;display: inline-block;margin-left: 10px;">
												<img style="display: block; width: 15px;" src="img/svg/call.svg" />
											</a>
										</template>
										
										<b-dropdown-text style="width: 240px;">
											{{ $t('InOrderToUse') }}
										</b-dropdown-text>
										<b-dropdown-divider></b-dropdown-divider>
										<b-dropdown-item-button>{{$t('Connect')}}</b-dropdown-item-button>
										<b-dropdown-item-button>{{ $t('CallUsingYour')}}</b-dropdown-item-button>
									</b-dropdown>
								</h5>
								<div>
									<p style="font-size: 13px;color: #1d2841;margin: 0px;"><strong>{{$t('Source')}} :</strong> {{ leadToQualify.source.name }}</p>
									<p style="font-size: 13px;color: #1d2841;margin: 0px;"><strong>{{$t('CreatedOn')}} :</strong> {{ leadToQualify.created_on }}</p>
								</div>
							</div>
							
							<div class="col-md-3">
								<div class="row">
									<div class="col-md-12 text-right icon-action-details">
										<i class="fa fa-trash" @click="deleteLead()"></i>
										<i class="fa fa-save" @click="saveChanges()"></i>
									</div>
									<div class="col-md-12">
										<select v-model="selectedStatus" :style="{backgroundColor: '#2959b5', borderColor: '#2959b5', borderRadius: '29px', padding: '5px', float: 'right', marginTop: '28px', color: '#fff'}">
											
											<!-- 
											<option value="1">Pending</option>
											<option value="2">Qualified</option>
											<option value="3">Unqualified</option>
											<option value="4">Unreachable</option>
											<option value="5">Call-back</option>
											<option value="6">Deleted</option>
											-->

											<option v-for="statusVal in status" v-bind:key="statusVal.id">
												{{ statusVal.state }}
											</option>

										</select>
									</div>
								</div>
							</div>
						</div>
						
						<b-tabs content-class="mt-2" class="view-leads-detail-md">
							<b-tab title="Lead details" active>
								<div class="row m-0 border-bottom pt-1 pb-1">
									<div class="col-md-6 pl-0 my-auto">
										<label class="m-0">{{ $t('FirstName') }}</label>
									</div>
									<div class="col-md-6 pr-0 pt-1 pb-1">
										<input type="text" class="form-control form-control-sm" :placeholder="$t('FirstName')" v-model="leadToQualify.firstname" /> <!-- -plaintext -->
										<i class=""></i>
									</div>
								</div>
								<div class="row m-0 border-bottom pt-1 pb-1">
									<div class="col-md-6 pl-0 my-auto">
										<label class="m-0">{{ $t('LastName') }}</label>
									</div>
									<div class="col-md-6 pr-0 pt-1 pb-1">
										<input type="text" class="form-control form-control-sm" :placeholder="$t('LastName')" v-model="leadToQualify.lastname" />
										<i class=""></i>
									</div>
								</div>
								<div class="row m-0 border-bottom pt-1 pb-1">
									<div class="col-md-6 pl-0 my-auto">
										<label class="m-0">{{ $t('Phone') }}</label>
									</div>
									<div class="col-md-6 pr-0 pt-1 pb-1">
										<input type="text" class="form-control form-control-sm" :placeholder="$t('Phone')" v-model="leadToQualify.phone" />
										<i class=""></i>
									</div>
								</div>
								<div class="row m-0 border-bottom pt-1 pb-1">
									<div class="col-md-6 pl-0 my-auto">
										<label class="m-0">{{ $t('Email') }}</label>
									</div>
									<div class="col-md-6 pr-0 pt-1 pb-1">	
										<input type="text" class="form-control form-control-sm" :placeholder="$t('Email')" v-model="leadToQualify.email" />
										<i class=""></i>
									</div>
								</div>
								<div style="display:flex;">
									<p style="color: #377dff;font-weight: 500;font-size: 14px; cursor: pointer;" class="pt-2 m-0 mr-5" @click="newFields.push( { name: '', value: '' } )">
										<i class="fa fa-plus-circle"></i> {{ $t('Addfield') }}
									</p>
									<p style="color: #ff5a5a;font-weight: 500;font-size: 14px; cursor: pointer;" class="pt-2 m-0" @click="newFields.splice(-1, 1)" v-show="newFields.length > 0">
										<i class="fa fa-minus-circle"></i> {{ $t('Minusfield') }}
									</p>
								</div>
								<template v-if="newFields.length > 0">
									<div class="row m-0 border-bottom pt-1 pb-1" v-for="(item, key) in newFields" v-bind:key="key">
										<div class="col-md-4 pl-0 pt-1 pb-1">
											<input type="text" class="form-control form-control-sm" :placeholder="$t('EnterNameField')" :value="item.name" />
										</div>
										<div class="col-md-2"></div>
										<div class="col-md-6 pr-0 pt-1 pb-1">
											<input type="text" class="form-control form-control-sm" :placeholder="$t('EnterValueField')" :value="item.value" />
											<i class=""></i>
										</div>
									</div>
								</template>
							</b-tab>
							<b-tab title="Send email">
								<div class="row mb-2">
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-4 my-auto">
												<b style="font-size: 14px;">{{$t('to')}} :</b>
											</div>
											<div class="col-md-8">
												<input type="text" class="form-control-plaintext form-control-sm border-bottom" :placeholder="$t('to')" :value="selectedLead.entry.email" disabled style="cursor: not-allowed;" />
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-4 my-auto">
												<b style="font-size: 14px;">{{$t('From')}} :</b>
											</div>
											<div class="col-md-8">
												<input type="text" class="form-control-plaintext form-control-sm border-bottom" :placeholder="$t('From')" :value="$auth.user().email" />
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-4 my-auto">
												<b style="font-size: 14px;">{{$t('Subject')}} :</b>
											</div>
											<div class="col-md-8">
												<input type="text" class="form-control-plaintext form-control-sm border-bottom" :placeholder="$t('Subject')" v-model="emailArray.Subject" />
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-4 my-auto pr-0">
												<b style="font-size: 14px;">
													{{ $t('Template')}} : 
													<router-link :to="{name: 'settings', params: {page: 'emailTemplates'}}" target="_blank">
														<i class="fa fa-plus-circle" style=" font-size: 20px; color: #007bff; float: right; padding-top: 3px; cursor: pointer; "></i>
													</router-link>
												</b>
											</div>
											<div class="col-md-8">
												<select class="form-control-plaintext form-control-sm border-bottom" @change="changeTemplate" v-model="emailArray.selectedTemplate">
													<option value="">{{ $t('choose')}}...</option>
													<option v-for="(item, key) in arrayEmailTemplates" v-bind:key="key" :value="key" v-html="item.name"></option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div class="row pt-2">
									<div class="col-md-12">
										<vue-editor ref="editorMd" v-model="emailArray.contentMessage" :editorToolbar="customToolbar"></vue-editor>
									</div>
									<div class="col-md-12 text-right pt-2">
										<button style="width: 10em; cursor: pointer;" class="btn btn-default btn-sm mr-4" @click="discardEmail()">{{$t('Discard')}}</button>
										<button style="color: #fff;width: 10em; cursor: pointer;" class="btn btn-primary btn-sm" @click="sendEmail()"><i class="fa fa-send" style="float: left;padding-top: 3px;"></i>{{ $t('Send')}}</button>
									</div>
								</div>
								
							</b-tab>
							<b-tab title="Lead Enrichment">
								
								<div style="height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<h3 style="text-align: center;margin: 1.3rem 0 0 0;font-size: 1.3rem;color: #f6523c;">{{ $t('ConnectyourEnrichmentProvider')}}</h3>
									<p style="text-align: center;font-size: 1.1rem;margin: 10px 0 0 0; line-height: 2rem;">{{ $t('IntegrationMessage')}}</p>
									<img style="display: block; margin: 1.5rem auto; width: 200px;" src="img/svg/enrichment1.svg" />
									<router-link :to="{ name : 'leadEnrichment' }" class="btn btn-primary" style="margin: 0 auto;display: block;width: 340px;padding: 1rem;font-size: 1.2rem;background: #377cff;border-color: #387bff;">
										{{ $t('LeadEnrichmentIntegrations')}}
									</router-link>
								</div>
								
							</b-tab>
							<b-tab title="Send SMS">
								<section class="ac-container">
									<div class="">
										<h3 style="text-align: center;margin: 2rem 0;font-size: 1.4rem;color: #f6523c;" v-html="$('SendYourSMSViaVoip')"></h3>
										<img style="display: block; margin: 2rem auto; width: 200px;" src="img/svg/sms.svg">
										<router-link :to="{ name : 'callAndSms' }" class="btn btn-primary" style="margin: 0 auto;display: block;width: 340px;padding: 1rem;font-size: 1.2rem;background: #377cff;border-color: #387bff;">
											{{$t('Connect')}}
										</router-link>
									</div>
								</section>
							</b-tab>
						</b-tabs>
						
					</div>
					<div class="col-md-5">
						<div class="row" style=" margin-bottom: 0; background: #fafafa; border-left: 1px solid #eee; ">
							<div class="col-md-12 pt-4">
								<div class="row">
									<div class="col-md-6">
										<div class="blockRight__title">
											<h3 style="font-size: 22px;">{{$t('Activities')}}</h3>
										</div>
									</div>
									<div class="text-right col-md-6">
										
										<div class="switch-leads row m-0">
											<!--div class="col-md-4">
												<i class="fa fa-chevron-left" @click="switchLeads('prev')" v-if="selectedLead.id > 0"></i>
											</div>
											<div class="col-md-4">
												<i class="fa fa-chevron-right" @click="switchLeads('next')" v-if="gridData.length != selectedLead.id + 1"></i>
											</div>
											<div class="col-md-4">
												<a @click="$bvModal.hide('bv-modal-qualification')" title="Close" class="modal-close">&times;</a>
											</div-->
										</div>
										
										
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="blockRight" style="">
									<div class="blockRight__inner" style="position: relative;padding: 1px 20px !important;">
										<div class="scrollbar scrollbar-primary" style="margin-bottom: 0; height: 420px;">
											<section class="comment-list block">
												<article class="comment-item" style=" border: 1px solid #ddd; border-radius: 8px; background: #fff; padding: 15px;">
													<section class="comment-body m-b-lg">
														<header style=" padding: 0; ">
															<a href="javascript:void(0);" style=" color: #ababab; font-size: 12px; font-weight: 300;">at 2020-01-09 12:17:12 by sahmoune mouad </a>
														</header>
														<div class="" style="color: #33353b;font-size: 14px;">
															<span class="text-warning icon-stack-base" style="font-size: 20px;">&#9679;</span> Note added
															<p style="margin-top: 10px;font-size: 14px;margin-bottom: 0px;">Testo</p>
														</div>
													</section>
												</article>
											</section>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="row" style="background-color: #fff;border-top: 1px solid #ccc; border-left: 1px solid #eee; padding-top: 2px;">
							<textarea class="note-textarea" rows="3" placeholder="Write a note about this lead..." style=" width: 74%; border: 0; padding: 20px 18px 20px 18px; resize: none; "></textarea>
							<a href="javascript:void(0);" class="btn btn-primary" id="sendnote" style="position: absolute;bottom: 1.3rem;right: 1.5rem;background: #377dff;border-color: #377dff;border-radius: 3px;padding: .6rem 1rem;">Add note</a>
						</div>
					</div>
					
				</div>
				
			</div>
		</b-modal>
		
		<b-modal id="bv-modal-qualification-null" centered scrollable hide-footer hide-header>
			<div class="d-block pt-4 pb-4">
				<div class="col-md-12">
					<center>you have no lead for your dispatching.</center>
					<center class="pt-3"><b-button variant="primary" class="pt-2 pb-2" style="width: 25%;">OK</b-button></center>
				</div>
			</div>
		</b-modal>
		
    </div>
</template>
<script>
	
	import { VueEditor } from "vue2-editor";
	
	export default {
		data() {
			return {
				//
				selectedLead : {
					"id": 0,
					"entry": { "id": 0, "fullname": "", "firstname": '', "lastname": '', "status": "", "score": '', "email": "", "phone": "", "generatedOn": "", "source": '', "color": "", "actions": "" }
				},
				newFields: [],
				
				emailArray: {
					Subject			: "",
					contentMessage	: "",
					selectedTemplate: ""
				},
				arrayEmailTemplates: [],
				
				customToolbar: [
					
					['leadname'],
					["bold", "italic", "underline"],
					/* ["blockquote", "code-block"], */
					[{ header: [6, 5, 4, 3, 2, 1, false] }],
					[{ 'align': [] }],
					[ { list: "ordered" }, { list: "bullet" }/* , { list: "check" } */], 
					[{ indent: "-1" }, { indent: "+1" }],
					[ { color: ['#000000', '#663d00', '#f5523c', '#377dff', '#ccc', '#ffc107', '#28a745'] }, { background: [] }], // dropdown with defaults from theme
					[{ 'font': [] }],
					/* [ { align: "" }, { align: "center" }, { align: "right" }, { align: "justify" } ], */
					
					
					// ["link", "image", "video"], 
					/* ["clean"] */ // remove formatting button
				],
				gridData: [],
				leadToQualify: {
					"id": 0,
					"fullname": "", 
					"firstname": '', 
					"lastname": '', 
					"status": "", 
					"score": '',
					"email": "",
					"phone": "",
					"created_on":"",
					"param": {},
					"source": {}
				},
				status: [],
				selectedStatus : 0,
			}
		},
		
		methods: {
			
			leadsQualification() {
				
				let that = this
				that.leadGetter = false
				
				let url   = 'qualification_leads'
				let method= 'POST'
				let data  = {type:'start'}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if(response.data.success ==  true) {
						
						that.leadToQualify.firstname = response.data.leadToQualify.personal_first_name;
						that.leadToQualify.lastname = response.data.leadToQualify.personal_last_name;
						that.leadToQualify.email = response.data.leadToQualify.personal_phone;
						that.leadToQualify.phone = response.data.leadToQualify.email;
						that.leadToQualify.created_on = response.data.leadToQualify.created_at;
						that.leadToQualify.param = response.data.leadToQualify.param;
						that.leadToQualify.source = response.data.leadToQualify.source;
						that.status = response.data.status ;
						that.selectedStatus = response.data.leadToQualify.param_id ;
						
					} else {
						console.log('Failed');
					}
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
			
			getUserInfo() {
				let that = this
				
				let url   = 'getUserInfo'
				let method= 'POST'
				let data  = {type:'start'}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					let userInfo = response.data.userInfo
					userInfo.myTemplates.forEach( function(model) {
						that.arrayEmailTemplates.push({id: model.id, name: model.name, sujet: model.sujet, body: model.body})
					} );
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
				// this.emailArray.Subject = 'Mouad'
				// this.emailArray.contentMessage = 'Mouad'
				// select * from emails_module where user_id=2523;
			},
			
			
			discardEmail() {
				this.emailArray.Subject = ''
				this.emailArray.contentMessage = ''
			},
			
			sendEmail() {
				alert('well done')
			},
			
			actionClick() {
				let that = this

				//that.leadToQualify = {}
				that.leadsQualification()
				
				that.$bvModal.show('bv-modal-qualification')
				
				setTimeout(function() {
					document.querySelector('.ql-leadname').innerText = 'Lead Name'
					document.querySelector('.ql-leadname').style.width = '6em'
					document.querySelector('.ql-leadname').style.fontSize = '14px'
					document.querySelector('.ql-leadname').style.padding = '6px 0px 0px 0px'
					document.querySelector('.ql-container').style.maxHeight = '350px'
					document.querySelector('.ql-container').style.overflowY = 'auto'
					// v-b-modal.model-template
					var toolbar = that.$refs.editorMd.quill.getModule('toolbar');
					toolbar.addHandler('leadname', function() { });
					var customButton = document.querySelector('.ql-leadname');
					customButton.addEventListener('click', function() {
						var range = that.$refs.editorMd.quill.getSelection();
						if (range) {
							that.$refs.editorMd.quill.insertText(range.index, " [[prospect_name]]");
						}
					});
				}, 400);
				
			},

			changeTemplate() {
				this.emailArray.Subject = this.arrayEmailTemplates[this.emailArray.selectedTemplate].sujet
				this.emailArray.contentMessage = this.arrayEmailTemplates[this.emailArray.selectedTemplate].body
				// this.emailArray.selectedTemplate = 
			},
			deleteLead() {
				console.log(this.selectedLead)
			},
			
			saveChanges() {
				console.log(this.selectedLead)
			},

			cancelQualification() {

				let that = this
				
				let url   = 'qualification_leads'
				let method= 'POST'
				let data  = {type:'cancel'}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if(response.data.success == true) {
						console.log('cancel');
						that.$bvModal.hide('bv-modal-qualification')
					}
					
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
		},
		
		mounted() {
			
			let that = this
			that.getUserInfo()
			//that.leadsQualification()
			
			
		},
		
		components: {
			//
			VueEditor
		},
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			}
		},
	}
</script>

<style lang="scss">
	.btn-clickstart-md {
		background-image: linear-gradient(150deg, #f5523c 0%, #f5523c 100%) !important;
		padding: 1.3rem 5rem;
		font-size: 1.5rem;
		border-radius: 6px;
		color: #fff !important;
		border: 0;
	}
	
	.close-qualification {
		cursor: pointer;
		-webkit-transition: all 0.2s;
		font-size: 20px;
		text-decoration: none;
		color: #333;
		margin: 0px;
		border: 1px solid #000;
		padding: 0px 10px;
		border-radius: 100%;
		vertical-align: middle;
		float: right;
	}
	
	
</style>